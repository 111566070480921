<template>
    <layout-heading>
        <portal to="title">
            <a v-if="!name" href="/suggestions">
                <img src="../../assets/images/icon-QR.svg" alt="">
                <span>Voting</span>
            </a>
            <a v-else>
                <img src="../../assets/images/logos/defactor.png" alt="">
                <span>Voting</span>
            </a>
        </portal>
        <template #heading-cta>
            <div class="dropdown" id="contract-dropdown" style="display: none;">
                <div class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h3 class="contract-name value-reset"></h3>
                    <a href="javascript: void 0;">View Contract Info</a>
                </div>
                <div class="dropdown-menu">
                    <div class="data">
                        <span class="label twoline">Name</span> <span
                        class="data-value contract-name value-reset"></span>
                    </div>
                    <div class="data">
                        <span class="label twoline">Contract Address</span> <span
                        class="data-value contract-address"></span>
                    </div>
                    <div class="data">
                        <span class="label twoline">Network</span> <span
                        class="data-value contract-network"></span>
                    </div>
                    <small class="change-contract-wrap">
                        <a href="/suggestions">Change Contract</a>
                    </small>
                </div>
            </div>
        </template>

        <div class="app-container suggestions-container" style="display: none;">
            <h2 class="current-suggestions" id="suggestions-list">Current Suggestions</h2>
            <p class="text-center what-are-suggestions-wrap">
                <a href="#what-are-suggestions" class="what-are-suggestions">What are suggestions?
                </a>
            </p>
            <div class="text-center staking-votes" style="display: none;">
                <div>Votes available are based on your staked tokens, minus votes you've used recently.</div>
                <p><a href="#" class="staking-link" style="display: none;">Open Staking</a></p>
                <p><strong>Votes Available: </strong> <span class="staking-votes-available"></span></p>
            </div>
            <div class="row align-items-center" style="display: none;">
                <div class="col-md-6">
                </div>
                <div class="col-md-4">
                    <input class="form-control" id="tag-filter" type="text"
                           placeholder="Filter by tag"/>
                </div>
                <div class="col-md-2">
                    <a href="#" id="clear-filter">Clear Filter</a>
                </div>
            </div>

            <div class="suggestions-table" id="suggestions-table">
                <div class="suggestions">
                    <div role="progressbar"
                         class="progress mdc-linear-progress mdc-linear-progress--indeterminate status-progress">
                        <div class="mdc-linear-progress__buffering-dots"></div>
                        <div class="mdc-linear-progress__buffer"></div>
                        <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
                            <span class="mdc-linear-progress__bar-inner"></span>
                        </div>
                        <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
                            <span class="mdc-linear-progress__bar-inner"></span>
                        </div>
                    </div>
                </div>
            </div>


            <h2 id="what-are-suggestions">What Are Suggestions?</h2>
            <div class="what-are-suggestions-content">
                <BookContent
                    address="0x8AEc040B9EC5850B4d93f180eB14AE533BEc10a3"
                    network="goerli"
                    folder="Descriptions"
                    :file="address">
                    <template #default>
                        <p>A suggestion can be a communication or some action you’d like to bring
                            attention to, and the community can vote on which ones they care about the
                            most.</p>
                        <div class="suggestions-only-description">
                            <p>
                                Suggestions can be created and voted on by token holders. All data on
                                suggestions are stored on the blockchain in a transparent manner. You
                                can
                                <a href="https://docs.blockwell.ai/contracts/suggestions.html"
                                   target="_blank">
                                    read more
                                </a>
                                in our documentation.
                            </p>
                        </div>
                        <div class="suggestions-proposals-description">
                            <p>
                                There are two stages of suggestions, commentary, ideas, and voting in
                                the form of:
                            </p>

                            <ul>
                                <li>
                                    <strong>Suggestions</strong> which are uncurated ideas that any
                                    tokenholder has a significant say in the curation and approval of
                                    for a formal vote.
                                </li>
                                <li>
                                    <strong>Proposals</strong> which are curated voting matters in which
                                    your token holdings are treated 1:1 as a vote on the matter,
                                    representing your respective interest (similar to how a shareholder
                                    cap-table would). This is the closest method to providing regulators
                                    and other investors with an efficient indication of actual investor
                                    sentiment.
                                </li>
                            </ul>
                        </div>
                    </template>
                </BookContent>
                <div class="suggestions-proposals-description">
                    <p><strong>How it Works:</strong></p>
                    <ol>
                        <li>
                            A Suggestions is created.
                            <a href="#" target="_blank" class="create-suggestion-link">Create
                                Suggestion
                            </a>
                        </li>
                        <li>
                            After the Suggestion receives <span
                            class="proposal-threshold"></span> Votes, the Suggestion becomes a
                            Proposal.
                            <a href="#suggestions">View Suggestions</a>
                        </li>
                        <li>
                            A Proposal can then be voted on once by each token holder.
                            <a href="#proposals">View Proposals</a>
                        </li>
                    </ol>
                </div>
                <p>
                    You can read more about Suggestions and Proposals in our documentation at
                    <a href="https://docs.blockwell.ai/wallet/voting.html"
                       target="_blank">
                        docs.blockwell.ai/wallet/voting.html
                    </a>
                </p>
            </div>
        </div>

        <div class="action-buttons d-flex">
        </div>

        <div class="results"></div>

        <div class="contract-container" style="display: none;">
            <h2>What Are Suggestions?</h2>
            <p>A suggestion can be a communication or some action you’d like to bring attention to,
                and the community can vote on which ones they care about the most.</p>
            <p>
                Suggestions can be created and voted on by token holders. All data on suggestions
                are stored on the blockchain in a transparent manner. You can
                <a href="https://docs.blockwell.ai/contracts/suggestions.html" target="_blank">
                    read more
                </a>
                in our documentation.
            </p>
            <div class="sample-contracts">
                <p>You can select one of these <strong>commonly viewed contracts</strong>:</p>
                <a class="btn btn-sm btn-outline-primary" href="#"
                   data-bwns="shopin">Shopin Tokenholder Voices
                </a>
                <a class="btn btn-sm btn-outline-primary" href="#"
                   data-address="0xE595564689D6E0206b095915C219a8c7a130cF7B"
                   data-network="rinkeby">Rinkeby Roar
                </a>
                <a class="btn btn-sm btn-outline-primary"
                   href="#"
                   data-address="0xff1E26eB2EEc30c8f36BB4BbA2321bc511c77e46"
                   data-network="rinkeby">Maintenance Token
                </a>
            </div>
            <p>
                Or you can <strong>enter a custom contract address</strong>:
            </p>
            <form action="#" method="post" class="contract-form">
                <div class="form-group">
                    <label>Contract Address</label>
                    <div class="row no-gutters">
                        <div class="col-md-7 contract-address-wrap">
                        </div>
                    </div>
                    <div class="invalid-feedback"></div>
                    <small class="form-text text-muted">
                        Enter the address of the contract.
                    </small>
                </div>
            </form>
            <p>
                Or you can <strong>select one of these recent contracts</strong>:
            </p>
            <div class="row">
                <div class="col-md-6">
                    <h6>Your Recent Contracts</h6>
                    <div class="user-recent"></div>
                </div>
                <div class="col-md-6">
                    <h6>Recently Deployed Contracts</h6>
                    <div class="recently-deployed"></div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="comments-modal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Comments</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <section class="comments-wrap"></section>
                        <p>
                            To leave a comment, you must
                            <a href="" target="_blank" class="vote-for">vote</a>
                            for the <span class="item-type"></span>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </layout-heading>
</template>

<script>
    import BookContent from "@/components/BookContent";
    import { getPrimeVotingData, loadPrimeUserData } from "@/views/happs/vote/prime-suggestions";
    import * as bootbox from "bootbox";
    import * as moment from "moment";
    import Api from '@/lib/api/WalletApi';
    import BigNumber from 'bignumber.js';

    import * as util from '@/assets/lib/util';
    import 'bootstrap';
    import * as voting from '@/assets/lib/voting';
    import Bwns from '@/lib/api/Bwns';
    import Dapp from '@/assets/lib/Dapp';
    import Recent from '@/lib/Recent';

    const bwns = new Bwns();
    const api = new Api('legacy');
    const recent = new Recent();

    let suggestions = [];
    let proposalsOnly = false;
    const dapp = new Dapp(api, loadContract, loadError);
    let $this;

    async function loadContract(dapp) {
        $this.address = dapp.contractAddress.toLowerCase();
        let token;

        if (dapp.contractId) {
            token = await api.getTokenData(dapp.contractId);
        } else {
            throw new Error("Contract not found.");
        }

        if (token && token.name) {
            $('.contract-name').text(token.name);
            document.title = `Voting for ${token.name} - Blockwell-QR`;
        } else {
            $('.contract-name').text('Suggestions');
            document.title = `Voting for ${dapp.contractAddress} - Blockwell-QR`;
        }

        const account = await api.getAccount();
        if (account) {
            $('.suggestions-container').addClass('user-data');
        }

        if (dapp.contractData?.bwtype === 1 && dapp.contractData?.bwver > 73) {
            suggestions = await getPrimeVotingData(dapp.web3, dapp.contractData);
        } else {
            suggestions = await voting.getVotingData(dapp.web3, dapp.contractAddress, dapp.contractId);
        }

        if (suggestions.oneVotePerAccount === null || suggestions.oneVotePerAccount === undefined) {
            bootbox.alert('This contract does not appear to have Voting or Suggestions.', () => {
                window.location = `/suggestions`;
            });
            return;
        }

        suggestions.token = token;

        if (suggestions.qrCodes) {
            $('.suggestions-table').addClass('show-vote');
        } else {
            $('.suggestions-table').removeClass('show-vote');
        }

        if (suggestions.proposals) {
            $('.suggestions-table').addClass('show-proposals');
            $('.proposals-filter').show();
            $('.proposal-threshold').text(suggestions.proposalThreshold);
            $('.proposals-info').show();
            $('.current-suggestions').text('Current Proposals and Suggestions');
            $('.suggestions-proposals-description').show();
            $('.suggestions-only-description').hide();
        } else {
            $('.suggestions-table').removeClass('show-proposals');
            $('.proposals-filter').hide();
            $('.current-suggestions').text('Current Suggestions');
            $('.suggestions-only-description').show();
            $('.suggestions-proposals-description').hide();
        }

        $('.suggestions').empty();
        renderSuggestions(null);

        try {
            let buttons = await voting.getActionButtons(dapp.web3, dapp.contractAddress);

            if (buttons) {
                let $buttons = $('.action-buttons');
                $buttons.empty();
                for (let button of buttons) {
                    addActionButton(window.location.protocol + "//" + window.location.host + "/" + button.code, button.text);
                }
            } else {
                addCreateSuggestionButton(suggestions.createQr);
            }
        } catch (err) {
            console.error(err);
            addCreateSuggestionButton(suggestions.createQr);
        }

        $('.create-suggestion-link').attr('href', suggestions.createQr);

        if (account) {
            try {
                await loadUserData(dapp, suggestions);
            } catch (err) {
                console.error('Error reading user data', err);
            } finally {
                $('.suggestions-container').removeClass('user-data');
            }
            await api.addToContractHistory(
                window.location.pathname + window.location.search,
                'Voting happ',
                dapp.contractAddress,
                dapp.network
            );
        }

        recent.addUserRecent('vote', {
            name: token.name,
            network: dapp.network,
            address: dapp.contractAddress
        });
    }

    async function loadUserData(dapp, suggestions) {

        if (dapp.contractData?.bwtype === 1 && dapp.contractData?.bwver > 73) {
            await loadPrimeUserData(dapp.web3, suggestions, await api.getAccount());
        } else {
            await voting.getUserData(dapp.web3, suggestions, await api.getAccount());
        }

        if (suggestions.votesUsed) {
            let votesLeft = suggestions.stake.minus(suggestions.votesUsed);
            if (votesLeft.lt(0)) {
                votesLeft = new BigNumber(0);
            }
            $('.staking-votes-available').text(votesLeft.toFormat());
            if (suggestions.contractData?.id === "66b834bb-c8bc-4bef-86ca-aeced9c22d12") {
                $('.staking-link').attr('href', "https://app.blockwell.ai/testr").show();
            }
            if (suggestions.contractData?.id === "be787228-ae6c-4da4-8d1f-48ee7b226aa0") {
                $('.staking-link').attr('href', "https://wallet.defactor.com").show();
            }
            $('.staking-votes').show();
        }

        for (let it of suggestions.suggestions) {
            let $element = $(`#suggestion-${it.id}`);

            let type;
            let refresh;
            if (suggestions.defaultVotesUsedTime) {
                type = "Stake";
                refresh = it.expiration;
            } else if (it.proposal) {
                type = 'Proposal';
                refresh = suggestions.proposalRefresh;
            } else {
                type = 'Suggestion';
                refresh = suggestions.voteRefresh;
            }
            let plural;
            if (it.user.votes.eq(1)) {
                plural = 'Vote';
            } else {
                plural = 'Votes';
            }

            if (it.user.votes.gt(0)) {
                $element.addClass('user-voted');
            }

            switch (votedType(it, suggestions)) {
                case 'balance-required':
                    $element.find('.vote-link').remove();
                    $element.find('.vote-column').prepend(`
                        <span class="text-muted">Not eligible to vote</span>
                        <a class="material-icons popover-trigger"
                           href="javascript: void 0;">
                            help
                        </a>
                        <div class="popover-content" style="display: none;">
                            Casting votes requires having a balance of ${suggestions.token.name}.
                        </div>
                `);
                    break;
                case 'limit':
                    $element.find('.vote-link').remove();
                    $element.find('.vote-column').prepend(`
                        <span class="you-voted">✓</span>
                        <span class="text-muted">You Voted</span>
                        <a class="material-icons popover-trigger"
                           href="javascript: void 0;">
                            help
                        </a>
                        <div class="popover-content" style="display: none;">
                            You already voted on this, and you can only vote once on an individual ${type}.
                        </div>
                `);
                    break;
                case 'voted-vote':
                    $element.find('.vote-column').prepend(`
                        <span class="you-voted">✓</span>
                        <span class="you-voted-text text-muted">You Cast ${it.user.votes.toFormat(0)} ${plural}</span>
                `);
                    $element.find('.vote-link').text('vote again');
                    break;
                case 'voted-refresh':
                    $element.find('.vote-link').remove();
                    $element.find('.vote-column').prepend(`
                        <span class="you-voted">✓</span>
                        <span class="you-voted-text text-muted">You Cast ${it.user.votes.toFormat(0)} ${plural}</span>
                        <span class="text-muted">This Week's Votes Spent</span>
                        <a class="material-icons popover-trigger"
                           href="javascript: void 0;">
                            help
                        </a>
                        <div class="popover-content" style="display: none;">
                            You've spent all of your ${type} votes for this week. You can vote again ${refresh.fromNow()}
                        </div>
                `);
                    break;
                case 'vote-refresh':
                    $element.find('.vote-link').remove();
                    $element.find('.vote-column').prepend(`
                        <span class="text-muted">This Week's Votes Spent</span>
                        <a class="material-icons popover-trigger"
                           href="javascript: void 0;">
                            help
                        </a>
                        <div class="popover-content" style="display: none;">
                            You've spent all of your ${type} votes for this week. You can vote again ${refresh.fromNow()}
                        </div>
                `);
                    break;
            }


            $element.find('.popover-trigger').popover({
                html: true,
                trigger: 'focus hover',
                placement: 'auto',
                content: function () {
                    let content = $(this).siblings('.popover-content').html();
                    return content;
                }
            });
        }
    }

    function votedType(suggestion, data) {
        if (!suggestion.proposal && !data.allocatedVoting && !data.requireBalanceForVote && !data.votesUsed) {
            if (data.oneVotePerAccount && suggestion.user.votes.gt(0)) {
                return 'limit';
            } else {
                return 'vote';
            }
        }

        if (!data.stake && data.balance.eq(0)) {
            return 'balance-required'
        }

        let oneVote;
        let votesLeft;

        if (data.votesUsed && data.stake) {
            votesLeft = data.stake.minus(data.votesUsed);
            oneVote = data.oneVotePerAccount;
        } else if (suggestion.proposal) {
            oneVote = data.proposalOneVotePerAccount;
            if (data.proposalRefresh.isBefore()) {
                votesLeft = new BigNumber(1);
            } else {
                votesLeft = data.proposalVotesLeft;
            }
        } else {
            oneVote = data.oneVotePerAccount;
            votesLeft = data.votesLeft;
            if (data.voteRefresh.isBefore()) {
                votesLeft = new BigNumber(1);
            } else {
                votesLeft = data.votesLeft;
            }
        }

        if (oneVote) {
            if (suggestion.user.votes.gt(0)) {
                return 'limit';
            } else if (votesLeft.gt(0)) {
                return 'vote';
            } else {
                return 'vote-refresh';
            }
        } else if (suggestion.user.votes.gt(0)) {
            if (votesLeft.gt(0)) {
                return 'voted-vote';
            } else {
                return 'voted-refresh';
            }
        } else if (votesLeft.gt(0)) {
            return 'vote';
        } else {
            return 'vote-refresh';
        }
    }

    async function loadError(err, dapp) {
        $('.suggestions').prepend(`<strong class="error">There was a problem loading the contract. An automatic error report has been created, please try again later.</strong>`);
        $('.suggestions > .progress').remove();
    }

    function addCreateSuggestionButton(createQr) {
        if (createQr) {
            $('.action-buttons').empty();
            addActionButton(createQr, '+ Create Suggestion')
        }
    }

    function addActionButton(link, text) {
        $('.action-buttons').append(`
                                    <div class="action-button">
                                        <a href="${link}" class="btn btn-primary">
                                            ${text}
                                        </a>
                                    </div>`);
    }

    function renderSuggestions(filter = null) {
        $('.suggestions').empty();
        let list = suggestions.suggestions;

        if (filter || proposalsOnly) {
            list = list.filter(it => {
                if (proposalsOnly) {
                    return !!it.proposal;
                }
                if (filter) {
                    return it.tag.toLowerCase().includes(filter.toLowerCase());
                }

                return true;
            });
        }

        if (list.length > 0) {
            $('.suggestions').append('<div id="proposals"></div>');
            let proposals = true;
            for (let it of list) {
                if (proposals && !it.proposal) {
                    proposals = false;
                    $('.suggestions').append('<div id="suggestions"></div>');
                }
                renderSuggestion(it);
            }

            $('.suggestions-table .popover-trigger').popover({
                trigger: 'focus hover'
            });
        } else {
            let content;

            if (suggestions.suggestions.length > 0) {
                if (suggestions.proposals) {
                    content = '<strong>All suggestions have been filtered out.</strong> <a href="javascript: void 0;" class="clear-filters">Click here</a> to clear filters.';
                }
            } else {
                content = '<strong>No suggestions in this contract.</strong> Create a suggestion to get started.';
            }

            $('.suggestions').html(content);

            $('.suggestions .clear-filters').click(() => {
                proposalsOnly = false;
                renderSuggestions(null);
            });
        }

        $('.suggestions').append(`
            <div class="suggestion">

                <div class="suggestion-id">
                    <span class="suggestion-id-info">
                        New Suggestion
                    </span>
                </div>
                <div class="suggestion-text-wrap">
                    <div class="suggestion-text-inner">
                        <div class="suggestion-text">
                            Want to add your own suggestion?
                        </div>
                        <div>
                            <a class="btn btn-sm btn-primary" style="margin-top: 15px;" href="${suggestions.createQr}">Create Suggestion</a>
                        </div>
                    </div>
                </div>
            </div>
        `);
    }

    function renderSuggestion(item) {
        let $row = voting.renderSuggestion(item);

        $row.find('.tag').click(function (ev) {
            ev.preventDefault();
            $('#tag-filter').val($(this).text()).trigger('input');
        });

        $('.suggestions').append($row);

        if ($row.find('.suggestion-text').height() > 130) {
            $row.find('.read-more').show();
            $row.find('.read-more-link').click(() => {
                let $inner = $row.find('.suggestion-text-inner');
                if ($inner.hasClass('expanded')) {
                    $inner.removeClass('expanded');
                } else {
                    $inner.addClass('expanded');
                }
                return false;
            });
        }

        $row.find('.comments-link').click(() => {
            $('.comments-wrap').empty().append(voting.renderComments(item.comments));
            let $modal = $('#comments-modal');

            let type = 'Suggestion';
            if (item.proposal) {
                type = 'Proposal';
            }

            $modal.find('.modal-title').text(`Comments for ${type} #${item.id}`);
            $modal.find('.item-type').text(type);
            $modal.find('.vote-for').attr('href', item.voteLink);

            $modal.modal();
            return false;
        });
    }

    function loadContractLink() {
        let address = $(this).data('address');
        let network = $(this).data('network');
        let bwns = $(this).data('bwns');

        if (bwns) {
            window.location = '/suggestions/' + bwns;
        } else if (address && network) {
            dapp.loadUrl(address, network);
            dapp.updateUrl(address, network);
        }
        return false;
    }

    export default {
        components: {BookContent},
        props: {
            name: String
        },
        data() {
            return {
                address: ""
            }
        },
        mounted: function () {
            $this = this;
            let urlParams = new URLSearchParams(window.location.search);
            proposalsOnly = !!urlParams.get('proposals');

            api.tryPromise('dapp_init', async () => {
                dapp.init();
                let name = this.name || this.$route.params.name;
                if (name) {
                    let ns = await bwns.resolve(name, 'vote');
                    if (ns) {
                        dapp.loadUrl(ns.address, ns.network);
                        return;
                    }
                }
                dapp.loadUrl();
            }, () => {
                $('.results').text('An error occurred, please try again later.').addClass('error');
            });

            $('#tag-filter').on('input', function () {
                renderSuggestions($(this).val());
            });

            $('#clear-filter').click(function (ev) {
                ev.preventDefault();
                $('#tag-filter').val('').trigger('input');
            });

            $('.sample-contracts a').click(loadContractLink);

            $('#proposals-checkbox').change(function () {
                proposalsOnly = this.checked;
                renderSuggestions();
            });

            dapp.loadRecents('vote', '0xda0C0DE511F642309218895452Cd51462bb5da34', loadContractLink);
        }
    }

</script>
