import axios from "axios";
import BigNumber from "bignumber.js";
import * as moment from "moment";
import primeAbi from "@/assets/data/PrimeToken.v76.abi.json";
import { Batcher } from "@/lib/eth/Batcher";
class PrimeSuggestionData {
}
export class PrimeSuggestion extends PrimeSuggestionData {
    constructor(settings) {
        super();
        Object.assign(this, settings);
    }
}
export async function getPrimeVotingData(web3, contractData) {
    const contract = new web3.eth.Contract(primeAbi, contractData.address);
    let batcher = new Batcher(web3)
        .setContract(contract)
        .addStructArray("getAllSuggestions")
        .addBoolean("suggestionsRestricted")
        .addBoolean("requireBalanceForVote")
        .addBoolean("requireBalanceForCreateSuggestion")
        .addBoolean("oneVotePerAccount")
        .addBoolean("stakedVoting")
        .addBoolean("allowNoVoteComments")
        .addBig("voteCost")
        .addInt("defaultVotesUsedTime");
    let result = await batcher.execute();
    let suggestions = result.getAllSuggestions.map((it) => structToSuggestion(it, contractData.parameters.decimals));
    let data = {
        suggestionsRestricted: result.suggestionsRestricted,
        requireBalanceForVote: result.requireBalanceForVote,
        requireBalanceForCreateSuggestion: result.requireBalanceForCreateSuggestion,
        oneVotePerAccount: result.oneVotePerAccount,
        stakedVoting: result.stakedVoting,
        allowNoVoteComments: result.stakedVoting,
        voteCost: result.voteCost,
        defaultVotesUsedTime: result.defaultVotesUsedTime,
        suggestions,
        decimals: contractData.parameters.decimals,
        createQr: null,
        contractData,
    };
    let qrCodes = false;
    let contractId = contractData.id;
    let voteQr;
    try {
        let res = await axios.get(process.env.VUE_APP_API_URL + `api/qr/suggestions/create/${contractId}`);
        data.createQr =
            window.location.protocol + "//" + window.location.host + "/" + res.data.shortcode;
        res = await axios.get(process.env.VUE_APP_API_URL + `api/qr/suggestions/multivote/${contractId}`);
        voteQr = window.location.protocol + "//" + window.location.host + "/" + res.data.shortcode;
        qrCodes = true;
    }
    catch (err) {
        console.error("Failed to load vote/proposal QR", err);
    }
    if (qrCodes) {
        for (let it of data.suggestions) {
            it.voteLink = voteQr + "?suggestionId=" + it.id;
            it.shareLink = voteQr + "/sharing?suggestionId=" + it.id;
        }
    }
    return data;
}
export async function loadPrimeUserData(web3, data, wallet) {
    let batcher = new Batcher(web3)
        .setContract(primeAbi, data.contractData.address)
        .addBig("votesUsedTotal", "used", [wallet])
        .addBig("stakeOf", "stake", [wallet])
        .addList("getAccountVotes", "votes", data.suggestions.length, {
        argsList: data.suggestions.map((it) => [wallet, it.id]),
    }, "bignumber");
    let res = await batcher.execute();
    let i = 0;
    for (let it of data.suggestions) {
        it.user = {
            votes: res.votes[i].div(`1e${data.decimals}`),
        };
        ++i;
    }
    data.votesUsed = res.used.div(`1e${data.decimals}`);
    data.stake = res.stake.div(`1e${data.decimals}`);
}
function structToSuggestion(struct, decimals) {
    let id = struct[0];
    let votes = struct[1];
    let creator = struct[3];
    let expiration = struct[4];
    let votesUsedTime = struct[5];
    let text = struct[6];
    return new PrimeSuggestion({
        id: parseInt(id),
        creator,
        suggestion: text,
        votes: new BigNumber(votes).div(`1e${decimals}`),
        expiration: moment.unix(parseInt(expiration)).local(),
        votesUsedTime: parseInt(votesUsedTime),
    });
}
