var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"proposal-votes-label\">backed by</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <a href=\"javascript: void 0;\" class=\"comments-link\">"
    + container.escapeExpression(((helper = (helper = helpers.comments || (depth0 != null ? depth0.comments : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"comments","hash":{},"data":data,"loc":{"start":{"line":43,"column":64},"end":{"line":43,"column":76}}}) : helper)))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"suggestion\" id=\"suggestion-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":39},"end":{"line":2,"column":45}}}) : helper)))
    + "\">\n    <div class=\"suggestion-id\">\n        <span class=\"suggestion-id-info\">\n            Suggestion\n        </span>\n        "
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":14}}}) : helper)))
    + "\n        <span>\n            <a class=\"material-icons popover-trigger\" href=\"javascript: void 0;\"\n               data-content=\"This is the ID number of the suggestion, and is used to reference the suggestion on the blockchain.\"\n            >help</a>\n        </span>\n    </div>\n    <div class=\"tag-column\">\n        <a href=\"#\" class=\"tag badge badge-pill badge-secondary suggestion-tag\"><span></span></a>\n    </div>\n    <div class=\"suggestion-text-wrap\">\n        <div class=\"suggestion-text-inner\">\n            <div class=\"suggestion-text\">\n\n            </div>\n        </div>\n        <div class=\"read-more\" style=\"display: none;\">\n            <a href=\"javascript: void 0;\" class=\"read-more-link\">Read More</a>\n        </div>\n    </div>\n    <div class=\"suggestion-votes\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.proposal : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":30,"column":15}}})) != null ? stack1 : "")
    + "        "
    + alias4(((helper = (helper = helpers.votes || (depth0 != null ? depth0.votes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"votes","hash":{},"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":31,"column":17}}}) : helper)))
    + "\n        <span class=\"votes-label\">\n            votes\n        </span>\n    </div>\n    <div class=\"vote-expiration\" style=\"display: none;\">\n        Voting ends on <span class=\"voting-ends\"></span>.\n    </div>\n    <div class=\"vote-column\">\n        <a href=\""
    + alias4(((helper = (helper = helpers.voteLink || (depth0 != null ? depth0.voteLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"voteLink","hash":{},"data":data,"loc":{"start":{"line":40,"column":17},"end":{"line":40,"column":29}}}) : helper)))
    + "\" class=\"vote-link\" target=\"_blank\">vote</a>\n        <a href=\""
    + alias4(((helper = (helper = helpers.shareLink || (depth0 != null ? depth0.shareLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shareLink","hash":{},"data":data,"loc":{"start":{"line":41,"column":17},"end":{"line":41,"column":30}}}) : helper)))
    + "\" class=\"share-link\" target=\"_blank\">share</a>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.comments : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});