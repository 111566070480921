import * as util from "@/assets/lib/util";
import dataStringFieldTemplate from "@/assets/templates/data-string-field.hbs";
import dataStringPreviewTemplate from "@/assets/templates/preview-data-string.hbs";
import axios from "axios";
import BigNumber from "bignumber.js";
import * as moment from "moment";
import abi from "../data/CommunityToken.abi";
import suggestionTemplate from "../templates/suggestion-row.hbs";
import commentTemplate from '../templates/vote-comment.hbs';
import Api from "@/lib/api/WalletApi";

export async function getVotingData(web3, address, contractId, id = null) {

    const contract = new web3.eth.Contract(abi, address);
    const api = new Api("legacy");
    const contr = await api.contracts.getContract(contractId);

    const count = parseInt(await contract.methods.suggestionCount().call());
    let ids = [];

    let batch = new web3.BatchRequest();

    for (let i = 0; i < count; i++) {
        if (!id || id.includes(i)) {
            batch.add(contract.methods.getSuggestionText(i).call.request());
            ids.push(i);
        }
    }
    batch.add(contract.methods.getAllVotes().call.request());
    batch.add(contract.methods.getProposalIds().call.request());
    batch.add(contract.methods.getAllProposalVotes().call.request());
    batch.add(contract.methods.proposalThreshold().call.request());
    batch.add(contract.methods.decimals().call.request());
    batch.add(contract.methods.oneVotePerAccount().call.request());

    let eventsPromise;
    if (contr.blockNumber) {
        eventsPromise = Promise.all([
            contract.getPastEvents('Votes', {
                fromBlock: contr.blockNumber,
                toBlock: contr.blockNumber + 3 * 24 * 60 * 60
            }),
            contract.getPastEvents('ProposalVotes', {
                fromBlock: contr.blockNumber,
                toBlock: contr.blockNumber + 3 * 24 * 60 * 60
            })
        ]);
    }

    let res;
    try {
        let result = await batch.execute();
        res = result?.response;
    } catch (err) {
        console.log('Caught error in batch calling');
        res = err.response.map(it => it.jsonrpc ? it.result : it);
        console.log(res);
    }


    let data = {
        suggestions: [],
        proposals: false,
        qrCodes: false,
        createQr: null,
        contract
    };

    let votes = res[ids.length];
    let proposals = res[ids.length + 1];
    let proposalVotes = res[ids.length + 2];
    let proposalThreshold = res[ids.length + 3];
    let decimals = res[ids.length + 4];
    data.oneVotePerAccount = res[ids.length + 5];

    data.proposalThreshold = proposalThreshold;
    data.decimals = decimals;

    if (proposals && proposalThreshold && proposalThreshold.gt(0)) {
        data.proposals = true;
        proposals = proposals.map(it => it.toNumber());
        if (decimals) {
            proposalVotes = proposalVotes.map(it => new BigNumber(it.toString(10)).div(`1e${decimals}`));
        }
    } else {
        proposals = [];
    }

    let voteQr;
    let proposalQr;
    if (contractId) {
        try {
            let res = await axios.get(process.env.VUE_APP_API_URL + `api/qr/suggestions/create/${contractId}`);
            data.createQr = window.location.protocol + "//" + window.location.host + "/" + res.data.shortcode;
            res = await axios.get(process.env.VUE_APP_API_URL + `api/qr/suggestions/vote/${contractId}`);
            voteQr = window.location.protocol + "//" + window.location.host + "/" + res.data.shortcode;
            res = await axios.get(process.env.VUE_APP_API_URL + `api/qr/suggestions/proposal/${contractId}`);
            proposalQr = window.location.protocol + "//" + window.location.host + "/" + res.data.shortcode;

            data.qrCodes = true;
        } catch (err) {
            console.error('Failed to load vote/proposal QR', err);
        }
    }

    let events;
    if (eventsPromise) {
        events = await eventsPromise.catch(err => {
            console.error(err);
            return null;
        });
    } else {
        events = [[], []];
    }

    let comments = [];

    if (events) {
        for (let event of events[0]) {
            if (event.returnValues.comment) {
                comments.push({
                    suggestionId: event.returnValues.suggestionId.toNumber(),
                    voter: event.returnValues.voter,
                    comment: event.returnValues.comment,
                    votes: new BigNumber(event.returnValues.votes.toString()).toFormat(),
                    proposal: false
                });
            }
        }
        for (let event of events[1]) {
            if (event.returnValues.comment) {
                comments.push({
                    suggestionId: event.returnValues.suggestionId.toNumber(),
                    voter: event.returnValues.voter,
                    comment: event.returnValues.comment,
                    votes: new BigNumber(event.returnValues.votes.toString()).shiftedBy(decimals * -1).sd(1).toFormat(0),
                    proposal: true
                });
            }
        }
    }

    if (comments.length > 0) {
        data.comments = comments;
    }

    let proposalCount = 0;
    let i = 0;
    for (let suggestionId of ids) {
        let it = {
            id: suggestionId,
            suggestion: res[i++],
            proposal: proposals.includes(suggestionId),
            votes: votes[suggestionId],
            comments: comments.filter(it => it.suggestionId === suggestionId)
        };

        if (it.proposal) {
            it.proposalVotes = proposalVotes[proposalCount++];
        }

        if (data.qrCodes) {
            if (it.proposal) {
                it.voteLink = proposalQr + "?suggestionId=" + suggestionId;
                it.shareLink = proposalQr + "/sharing?suggestionId=" + suggestionId;
            } else {
                it.voteLink = voteQr + "?suggestionId=" + suggestionId;
                it.shareLink = voteQr + "/sharing?suggestionId=" + suggestionId;
            }
        }

        data.suggestions.push(it);
    }

    data.suggestions.sort((a, b) => {
        if (a.proposal && !b.proposal) {
            return -1;
        }
        if (b.proposal && !a.proposal) {
            return 1;
        }
        if (a.proposal && b.proposal) {
            return b.proposalVotes.minus(a.proposalVotes).toNumber();
        }
        if (a.votes !== b.votes) {
            return b.votes - a.votes;
        }
        return a.id - b.id;
    });

    return data;
}

export async function getUserData(web3, data, wallet) {
    const contract = data.contract;
    let batch = new web3.BatchRequest();

    for (let it of data.suggestions) {
        if (it.proposal) {
            batch.add(contract.methods.getAccountProposalVotes(wallet, it.id).call.request())
        } else {
            batch.add(contract.methods.getAccountVotes(wallet, it.id).call.request())
        }
    }

    batch.add(contract.methods.oneVotePerAccount().call.request());
    batch.add(contract.methods.proposalOneVotePerAccount().call.request());
    batch.add(contract.methods.allocatedVoting().call.request());
    batch.add(contract.methods.requireBalanceForVote().call.request());
    batch.add(contract.methods.lastAllocation().call.request());
    batch.add(contract.methods.allocationPeriod().call.request());
    batch.add(contract.methods.proposalLastAllocation().call.request());
    batch.add(contract.methods.proposalAllocationPeriod().call.request());
    batch.add(contract.methods.votesLeft(wallet).call.request());
    batch.add(contract.methods.proposalVotesLeft(wallet).call.request());
    batch.add(contract.methods.balanceOf(wallet).call.request());

    let res;
    try {
        let result = await batch.execute();
        res = result?.response;
    } catch (err) {
        console.log('Caught error in batch calling');
        res = err.response.map(it => it.jsonrpc ? it.result : it);
    }


    let i = 0;

    for (let it of data.suggestions) {
        let user = {};
        if (it.proposal) {
            user.votes = new BigNumber(res[i++].toString()).shiftedBy(data.decimals * -1);
        } else {
            user.votes = new BigNumber(res[i++].toString());
        }

        it.user = user;
    }

    data.oneVotePerAccount = res[i++];
    data.proposalOneVotePerAccount = res[i++];
    data.allocatedVoting = res[i++];
    data.requireBalanceForVote = res[i++];
    data.lastAllocation = res[i++];
    data.allocationPeriod = res[i++];
    data.proposalLastAllocation = res[i++];
    data.proposalAllocationPeriod = res[i++];

    // Don't try to do the rest if the contract doesn't support allocated voting
    if (data.allocatedVoting !== undefined) {
        data.votesLeft = new BigNumber(res[i++].toString());
        data.proposalVotesLeft = new BigNumber(res[i++].toString());
        data.balance = new BigNumber(res[i++].toString());

        data.voteRefresh = moment.unix(data.lastAllocation.toNumber() + data.allocationPeriod.toNumber());
        data.proposalRefresh = moment.unix(data.proposalLastAllocation.toNumber() + data.proposalAllocationPeriod.toNumber());
    }
}

export function renderSuggestion(item) {
    let voteLink = "";
    if (item.voteLink) {
        voteLink = item.voteLink
    }
    let shareLink = "";
    if (item.shareLink) {
        shareLink = item.shareLink
    }
    let votes;
    if (item.proposal) {
        votes = item.proposalVotes.toFormat(0);
    } else {
        votes = item.votes;
    }

    let comments = null;

    if (item.comments && item.comments.length > 0) {
        if (item.comments.length === 1) {
            comments = "1 comment";
        } else {
            comments = item.comments.length + " comments";
        }
    }

    let $row = $(suggestionTemplate({
        voteLink,
        shareLink,
        votes,
        id: item.id,
        comments,
        proposal: item.proposal
    }));

    if (item.proposal) {
        $row.addClass('proposal');
        $row.find('.suggestion-id-info').text('Proposal');
        $row.find('.votes-label').text('tokens');
    }

    if (item.expiration) {
        $row.find('.voting-ends').text(item.expiration.format('lll'));
        $row.find('.vote-expiration').show();
    }

    updateSuggestion($row, item.suggestion, false);

    return $row;
}

export function updateSuggestion(element, text, truncate = true) {
    let json;
    if (text === Object(text)) {
        json = text;
    } else {
        try {
            json = JSON.parse(text);
        } catch (err) {
            // Not valid JSON, fall back to text based
        }
    }

    if (json) {
        let tag;
        let others = [];

        for (let [key, value] of Object.entries(json)) {
            if (key.toLowerCase() === "tag") {
                tag = value;
            } else {
                others.push([key, value]);
            }
        }

        if (tag) {
            element.find('.suggestion-tag span').text(tag);
            element.find('.suggestion-tag').show();
        } else {
            element.find('.suggestion-tag').hide();
        }

        if (others.length === 1) {
            element.find('.suggestion-text').text(others[0][1]);
        } else {
            let $wrap = $(dataStringPreviewTemplate());
            let $fields = $wrap.find('.data-string-fields');
            for (let [key, value] of others) {
                let $field = $(dataStringFieldTemplate({
                    name: key,
                    value
                }));
                $fields.append($field);
            }

            element.find('.suggestion-text').empty().append($wrap);
        }
    } else {
        let {suggestion, tag} = util.parseSuggestion(text, truncate);
        if (tag) {
            element.find('.suggestion-tag span').text(tag);
            element.find('.suggestion-tag').show();
        } else {
            element.find('.suggestion-tag').hide();
        }
        if (suggestion != "") {
            element.find('.suggestion-text').text(suggestion);
        } else {
            element.find('.suggestion-text').text('This suggestion has no description.').addClass('no-text');
        }
    }
    element.find('.select-suggestion').hide();
    element.find('.change-suggestion').show();
}

export async function getActionButtons(web3, address) {
    try {
        const contract = new web3.eth.Contract(abi, address);

        let buttons = await contract.methods.getBwQuill1(address).call();
        let list = JSON.parse(buttons);

        return list;
    } catch (err) {
        return null;
    }
}

export function renderComments(comments) {
    let elements = [];

    for (let comment of comments) {
        let footer;

        if (comment.proposal) {
            footer = `Voted with ~${comment.votes} votes on the Proposal`;
        } else {
            footer = `Voted on the Suggestion`;
        }

        let $comm = $(commentTemplate({
            address: comment.voter,
            comment: comment.comment,
            proposal: comment.proposal,
            footer
        }));

        elements.push($comm);
    }

    return elements;
}
